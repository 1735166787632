
import React from 'react'; 
import { useState, useEffect } from 'react';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Row, Container, Col, Navbar, Nav }from 'react-bootstrap';
import ReactImageFallback from "react-image-fallback";
import Web3 from 'web3'; 
import axios from 'axios';
//import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
//import { ConnectButton } from 'web3uikit';

import 'video-react/dist/video-react.css' // import css
//import { Player } from 'video-react';
import {NFT_ABI, NFT_Contract_Address} from './NFT_1_ABI';

function App() {

  const [ networkID, setnetworkID ] = useState('');

  const [ network, setnetwork ] = useState(''); 

  const [ account, setaccount ] = useState({accountid:'', balanc:''   });

  const [ tokenInput, setTokenInput ] = useState('')
  const [tokenInfo, setTokenInfo] = useState('undefined')
  const [imageURL, setImageURL ] = useState('')

  const [transHash, setTransHash] = useState('')
  const [ setTokenBal] = useState('')

  // const [ block, setblock ] = useState({});
  let web3 = null;

  const enable = async () => { 
    // window.alert('hello')
    window.location.reload()
    // if (window.ethereum) {
    //   await window.ethereum.send('eth_requestAccounts');
    //   window.web3 = new Web3(window.ethereum);
    //   return true;
    // }
    // await window.ethereum.enable()  

  }
  const loadBlock = async () => {

    try {

      if (window.ethereum != null) {
            web3 = new Web3( window.ethereum )
            console.log('web 3', web3 )
        await window.ethereum.enable()
      } else {
        alert('Please install MetaMask to use this dApp');
        return;
      }

  
  //    const web3 = new Web3 ( Web3.givenProvider || "http://localhost:8545" );
  
      const networktype = await web3.eth.net.getNetworkType();
  
      console.log( 'Network is: ', networktype);
  
      setnetwork(  networktype ); 
  
      const networkID = await web3.eth.net.getId(); 
  
      setnetworkID( networkID ); 

      const accounts = await web3.eth.getAccounts();

   //   const address = '0xD49bD0D7C303c43F098B81702e54E796372d66Fb'; 

  //    const balance = await web3.eth.getBalance(address);
      const balance = await web3.eth.getBalance(accounts[0]);

      setaccount({ accountid: accounts[0], bal: balance /1e18 }  );


      const blockinfo = await web3.eth.getBlock('latest');

      console.log('block info: ', blockinfo)

      // setblock( blockinfo ); 
      
    } catch (error) {
      console.log( error ); 
    }
  
  } 
  
  const getTokenInfo = async () => {

    const { data } = await axios.get( tokenInput )
    console.log('after axios ', data)
    setTokenInfo( data) 
    console.log('data result > ', data.image)
    setImageURL( data.image )
  }


  const mintNFT = async () => {

    try {
        if(window.ethereum != null){
          web3 = new Web3( window.ethereum )
          console.log('web 3', web3 )
          await window.ethereum.enable()
        } else{
          alert('Please instal metaMask to use the dApp');
          return;
        }
        const accounts = await web3.eth.getAccounts();
        const tc = new web3.eth.Contract(NFT_ABI, NFT_Contract_Address );
        const recipient = accounts[0];

        console.log('token input URI > ',tokenInput);

        const newToken = await tc.methods.mintNFT(recipient, tokenInput).send ({
          from: accounts[0],
          to: NFT_Contract_Address},
          (err, res) =>{
          if (err) {
            console.log('Create NFT failed >', err)
            return
          } else {
            console.log('Created NFT >', res)
            setTransHash(res);
          }
        
        })

        const balToken = await tc.methods.balanceOf(recipient).call ({
          from: accounts[0],
          to: NFT_Contract_Address},
          (err, res) => {
            if (err){
              console.log('err in getting balance', err)
              return
            } else {
              console.log('Got bal > ', res )
              setTokenBal(res)
            }
          });
       console.log('new Token > ', newToken);
       console.log('bal token> ', balToken);
    } catch (error) {
      console.log('error >', error)
    }
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleInput = ( e ) => {
    
    e.preventDefault()
    console.log('event input > ',e.target.value)
    setTokenInput( e.target.value)

  }

  useEffect(() => {
    loadBlock(); 
   // eslint-disable-next-line
 }, []);

  if ( typeof tokenInfo  === 'undefined') {
    return 'loading . . .'
  }

  return (
 
    <Container className="App mores" >
        <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
       <Navbar bg="dark" variant="dark">
    <Container>
  
    <Nav className="me-auto">
      <Nav.Link href="#utility">Utility</Nav.Link>
      <Nav.Link href="#roadmap">Roadmap</Nav.Link>
      <Nav.Link href="#mint">Mint</Nav.Link>
    </Nav>
    </Container>
  </Navbar>
        <div className='mores' style={{backgroundImage: 'url(https://assets-global.website-files.com/61ecff3…/61efb52…_blur-blob-nft-webflow-template.svg)'}}>
      <div >
      
      <Row className="toptop collection" style={{ paddingTop:'10px'}} >
      <div className="connect-button" ><nk-dropkit apikey="mN0hNtuLEePyHNVXUfMw"   /></div>
    <Col className="top-text" style={{   paddingTop:' 118px',
    paddingLeft: '119px',
    textAlign: 'left'}}>   
     <h1 style={{fontSize: '40px', marginBottom:'2px'}}> Esoteric Knowledge </h1>
    <h3 className="subhead">A luminous NFT collection</h3>
   <p> We are royal seekers of the mystic wisdom...we're also in the midsts of developing an NFT 
    & platform for underground artists, fans, and collectors of musical artifacts. Our NFTs come with 
    full licensing rights to select music, Exclusive content and intimate esoteric knowledge build sessions with profound minds.
    </p>
  <div>
    <Button href="https://discord.gg/cENAEeDv">Join Discord</Button> <Button disabled>Mint Now</Button>
    </div>
    </Col>
   
   
    <Col  ><div class="firerootmic" ><img style={{width:'245px', transform: 'rotate(20deg)'}} src={'https://gateway.pinata.cloud/ipfs/QmPF8hwUQXr4qac9R2DCb5bNvKJ8gsdRkKebL1YZU4qDwE'} alt='NFT pic'></img></div>
      </Col>


    </Row>
     <Row className="collection"  style={{     backgroundImage: 'linear-gradient(181deg, black, black', padding:'126px'}}>
     <Row className="neon-border1">
   

      <Col>
      <div style={{   
    margin: 'auto'}}>
	    <img src={"https://rvanfts.com/test/esoteric/wu-gif.gif"} style={{   
    marginTop: '-28px'}}alt='NFT pic' width='400' height='500'/>
  
  </div>
  </Col>
     
     
  {/*  <Player>
      <source src="https://innercitymeta.com/esoterickeldg.mp3" />
    </Player>*/
}
   
     { /* <p> Balance: <span className='clearText'> { account.bal }</span> Ether </p>*/}
      {/* <p> Latest block:  { block } </p> */}
   <Col className="col-util" idName="utility" id="utility">
   <h3 style={{ textAlign:'left'}}> Utility for the Community  </h3>
   <ul style={{listStyle: "none", textAlign:'left', paddingLeft: '0px'}}>
     <li>↠ Collect XP and unlock Perks, Virtual Reality Experiences, and more!</li>
     <li>↠ Unlock licensing rights to music and other digital goods</li>
     <li>↠ Work together as a community to unlock new music and experiences </li>
     <li>↠ Monthly Podcraft sessions with guest hosts and speakers </li>
     <li>↠ Earn exlusive badges and perks for community involvement </li>


   </ul>
   <Row>
<Col>

<img style={{ width: '447px', marginLeft: '-70px'}}src={"https://darrylarrington.com/movinggems.gif"} alt='NFT pic'></img>

</Col>
</Row>

   </Col>

   </Row>
    </Row>


    <Row  idName="roadmap" id="roadmap" style={{  backgroundColor: '#e9e9e9', backgroundImage: 'url(https://innercitymeta.com/mic_space.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain', backgroundPosition: 'center' }} >
    
<div style={{ backgroundImage:  'linear-gradient(183deg, rgb(0 0 0), rgb(0 0 0 / 43%), rgba(251, 251, 251, 0))'}}>
   <h2 style={{color:'white'}}>Road Map</h2>
    <VerticalTimeline>
   
  <VerticalTimelineElement
    className="vertical-timeline-element--work"    
    contentStyle={{ background: 'white', color: 'black',  boxShadow: 'black 0px 0px 14px, black 0px 0px 5px' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
   
    iconStyle={{ background: 'white', color: '#fff', backgroundSize: 'cover',
    backgroundPosition: 'center', backgroundImage:'url(https://gateway.pinata.cloud/ipfs/QmYSJwiK8RrZWMR2teYRcUb76Xz1RvWFMKRyG2jdxzjEUM)' }}
    //icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 1</h3>
    <h4 className="vertical-timeline-element-subtitle">Root Chakra (Muladhara)</h4>
    <p>
    <ul  style={{textAlign:'left'}}>
    <li >Pre Release </li>
    <li >Partnerships</li>
    <li >Marketing</li>
    <li >Tech Development</li>
    <li >Community Building </li>
    <li >Official Release (Until Sold Out)</li>
    </ul>
    
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px' }}
 
    iconStyle={{ background: 'white', color: '#fff', }}
    //icon={}
    
  >
    <h3 className="vertical-timeline-element-title">Phase 2</h3>
    <h4 className="vertical-timeline-element-subtitle">Sacral Chakra (Swadhisthana)</h4>
    <p>
    <ul  style={{textAlign:'left'}}>
    <li >Develop royalty contracts </li>
    <li >Pre-release next chakra nft</li>
    <li >Exclusive Giveaways</li>
    <li >Live Wutang Show:</li>
    <li >Intimate Q&A with Killah Priest (NFT holders access)</li>
    <li >Exclusive Music Show Case</li>
    <li >Exclusive Member Music Critique</li>
    <li >Official Release (Until Sold Out)</li>
    </ul>


    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
   
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px' }}
    iconStyle={{ background: 'white', color: '#fff', }}
    //icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 3</h3>
    <h4 className="vertical-timeline-element-subtitle">Solar Plexus Chakra (Manipura)</h4>
    <p>
      
    <ul  style={{textAlign:'left'}}>
    <li >In Person Networking Events: 200 xp</li>
    <li >Pre-release next chakra nft: 1200 xp</li>
    <li >Add perks to the community: 700 xp</li>
    <li >Live Wutang Show: 700 xp</li>
    <li >Intimate Q&A with Killah Priest (NFT holders access): 700 xp</li>
    <li >Exclusive Music Show Case: 700 xp</li>
    <li >VR+ AR Experiences and reveals : 700 xp</li>
    <li >Official Release (Until Sold Out): 700 xp</li>
    </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px'}}
    iconStyle={{ background: 'white', color: '#fff', }}
    //icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 4</h3>
    <h4 className="vertical-timeline-element-subtitle">Heart Chakra (Anahata)</h4>
    <p>
    <ul>
	<li>Pre-release next chakra nft</li>
	<li>Live Building Sessions with knowledgeable teachers</li>
	<li>Collaborative projects</li>
	<li>Official Release (Until Sold Out)</li>
	<li>VR+ AR Experiences and reveals</li>
</ul>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px' }}
    iconStyle={{ background: 'white', color: '#fff', }}
    //icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 5</h3>
    <h4 className="vertical-timeline-element-subtitle">Throat Chakra (Vishuddha)</h4>
    <p>
    <ul>
	<li>Pre-release next chakra nft</li>
	<li>Official Release (until sold out first 100)</li>
	<li>VR+ AR Experiences and reveals</li>
</ul>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px'}}
    iconStyle={{ background: 'white', color: '#fff' }}
    //icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 6</h3>
    <h4 className="vertical-timeline-element-subtitle">Third-Eye Chakra (Ajna)</h4>
    <p>
    <ul>
	<li>Pre-release next chakra nft</li>
	<li>Official Release (Until Sold Out 100)</li>
	<li>VR+ AR Experiences and reveals</li>
</ul>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    
    contentStyle={{boxShadow: 'black 0px 0px 14px, black 0px 0px 5px' }}
    iconStyle={{ background: 'white', color: '#fff',backgroundSize: 'cover',
    backgroundPosition: 'center', backgroundImage:'url(https://gateway.pinata.cloud/ipfs/QmYSJwiK8RrZWMR2teYRcUb76Xz1RvWFMKRyG2jdxzjEUM)'  }}
    ////icon={}
  >
    <h3 className="vertical-timeline-element-title">Phase 7</h3>
    <h4 className="vertical-timeline-element-subtitle">Crown Chakra (Sahasrara)</h4>
    <p>
    <ul>
	<li>Pre-release next chakra nft</li>
	<li>Offical Esoteric Knowledge Album Drop</li>
	<li>Official Release (Until Sold Out 100)</li>
	<li>VR+ AR Experiences and reveals</li>
</ul>
    </p>
  </VerticalTimelineElement>
 
</VerticalTimeline> 
<Button text="Virtual Entertainment" style={{display:'none'}} onClick={ () => openInNewTab('https://innercitymeta.com/extras/examples/vive/space.html') }  > Unlock VR(Virtual Reality) </Button>



</div>
<Row  id="mint" style={{    backgroundColor:' black', height: '44px', backgroundImage: 'linear-gradient(180deg, rgb(233 233 233), rgb(232 232 232 / 97%), rgb(0 0 0))'}}></Row>
    <Col  style={{color: 'white', backgroundColor: 'black', backgroundSize: 'cover',
    backgroundPosition: 'center', backgroundImage:  'linear-gradient(183deg, rgb(0 0 0), rgb(0 0 0 / 43%), rgba(251, 251, 251, 0))'     }}>
      <div class="left-group">

      <ReactImageFallback
                    src={imageURL} 
                    fallbackImage="https://innercitymeta.com/esoteric/AqCa.gif"
                    initialImage="loader.gif"
                    alt="cool image should be here"
                    className="my-image" width='300' height='200'  />
<div class="data-id" ><p style={{ fontSize: '12px', textAlign:'center'}} > Net ID: <span style={{fontWeight:'bold'}}>{ networkID } | </span> Network: <span style={{fontWeight:'bold'}}>{ network } | Account: <span > { account.accountid } </span> </span>  </p></div>
      <div style={{display:'none'}}>
      <div>
      
      <button className='btn2' onClick={ () => enable() } > Refresh </button>
      <button className='btn2' onClick={ () => getTokenInfo() } > Get NFT </button>
      </div>
      
        <input  name='meta'  
        onChange={ handleInput } />
      <>
      
      <Button className="mintbtn"onClick={() => mintNFT()} text="Mint Your Nft" theme="primary" type="button">
      Mint Your Nft
        </Button>   

        
    </>
   
    
      <div>
     
      
      </div>
      <div >
         { /*<button className='btn2' onClick={() => mintNFT()} > Create NFT</button>*/}
        </div>
        {transHash === ' ' ? <p>. . .</p>
      : ( <Row className="tokeninfo">
       <Col>  <h2 style={{textAlign: 'center'}} >Transaction Hash: <span>{transHash}</span> </h2></Col>
       
        
       
        </Row>
        )}

        </div>
    </div>
   </Col>
   </Row>
   </div>
    </div>
    </Container>
  );
}

export default App;

// https://ethereum.org/en/developers/tutorials/how-to-write-and-deploy-an-nft/

//   https://betterprogramming.pub/generate-your-nft-metadata-11a878c082b9

